<template>
  <div class="curriculum">
    <div class="swiper-box">
      <el-carousel :interval="5000" arrow="always">
        <el-carousel-item v-for="item in swiperlist" :key="item.id">
          <img
            :src="global.imgUrl + item.imgUrl"
            alt=""
            @click="swiperLinkUrl(item.linkUrl)"
          />
          <div class="swiper-title">{{ item.title }}</div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- <div class="activity-type-box">
      <div class="header">课程类型:</div>
      <div class="activity-label-box">
        <div
          class="activity-type-label"
          @click="changelabel(item)"
          :class="item.title == activetext ? 'active-label' : null"
          v-for="item in activitylist"
        >
          {{ item.title }}
        </div>
      </div>
    </div> -->
    <div class="curriculum-box">
      <div class="curriculum-items" v-for="item in actividatalist">
        <div>
          <img
            :src="global.imgUrl + item.imgUrl"
            @click="godetails(item, 7)"
            alt=""
          />
          <i
            data-v-f8ca7f8c=""
            class="iconfont icon-bofang"
            @click.stop="goVideoLink(item)"
          ></i>
        </div>

        <p class="title" @click="godetails(item, 7)">
          {{ item.title }}
        </p>
        <div class="scale-box">
          <div class="people" v-if="item.articleFields[4]">
            {{ item.articleFields[4].fieldValue }}人已观看
          </div>
          <div class="payfor" v-if="item.articleFields">
            {{ item.articleFields[0].fieldValue == "1" ? "免费" : "付费" }}
          </div>
        </div>
      </div>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="6"
      layout="prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import {
  getCoursetype,
  getCourselist,
  getCourselisttype,
  getactivswiper,
} from "@/api";
export default {
  name: "curriculum",
  data() {
    return {
      activitylist: [],
      activetext: "全部",
      currentPage: 1,
      actividatalist: [],
      total: 0,
      swiperlist: [],
    };
  },
  methods: {
    changelabel(item) {
      this.activetext = item.title;
      getCourselisttype(item.id).then((res) => {
        this.actividatalist = res.data;
        this.total = parseInt(
          res.headers["x-pagination"].split(",")[0].split(":")[1]
        ); //总长度
        console.log(this.total);
      });
    },
    handleCurrentChange(e) {
      getCourselist(e).then((res) => {
        this.actividatalist = res.data;
      });
      console.log(e);
    },
    handleSizeChange(e) {
      console.log(e);
    },
    goVideoLink(item) {
      window.open(item.linkUrl, "_blank");
    },
    swiperLinkUrl(item) {
      window.open(item, "_blank");
    },
    godetails(item, type) {
      console.log(item);
      localStorage.setItem("secondpath", JSON.stringify(this.$route.path));
      // this.$router.push(`/DetailsPage?id=${item.id}&type=${type}`);
      let routeData = this.$router.resolve({
        name: "DetailsPage",
        query: {
          id: item.id,
          type,
          title: item.title || item.Title,
        },
      });
      window.open(routeData.href, "_blank");
    },
    start() {
      getCoursetype().then((res) => {
        console.log("分类列表", res);
        this.activitylist = res.data;
      });
      getCourselist(1).then((res) => {
        console.log("课程列表", res);
        this.actividatalist = res.data;
        this.total = parseInt(
          res.headers["x-pagination"].split(",")[0].split(":")[1]
        );
        console.log(this.total);
      });
      getactivswiper(496).then((res) => {
        console.log(res);
        this.swiperlist = res.data;
      });
    },
  },
  mounted() {
    this.start();
  },
};
</script>

<style lang="less" scoped>
.curriculum {
  width: 1280px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 50px;
  .swiper-box {
    img {
      width: 100%;
      height: 100%;
    }
    /deep/ .el-carousel__container {
      height: 450px;
    }
    /deep/ .el-carousel__indicators--horizontal {
      left: 14%;
      bottom: 30px;
    }
    /deep/ .el-carousel__button {
      // 默认按钮的颜色大小等
      width: 24px;
      height: 4px;
      border: none;
      background: hsla(0, 0%, 100%, 0.4);
      opacity: 1;
    }
    /deep/ .is-active .el-carousel__button {
      // 选中图片的颜色
      background: #f08200;
      opacity: 1;
    }
    /deep/ .el-carousel__arrow {
      border-radius: 0;
    }
    /deep/ .el-carousel__arrow--right {
      right: 0;
      background: #0404044a;
    }
    /deep/ .el-carousel__arrow--left {
      left: 0;
      background: #0404044a;
    }
  }
  .activity-label-box {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    // margin-left: 20px;
  }
  .activity-type-box {
    background: white;
    // margin-top: 20px;
    padding: 20px;
    display: flex;
    .header {
      color: #adaca9;
      font-size: 14px;
      font-weight: bold;
      padding-top: 8px;
    }
    .activity-type-label {
      margin: 0 10px;
      margin-bottom: 10px;
      font-size: 14px;
      padding: 8px 15px;
      cursor: pointer;
    }
    .active-label {
      background: #314483;
      color: #fff;
      border-radius: 3px;
    }
  }
  .curriculum-box {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: space-around;
    .curriculum-items {
      width: 32%;
      height: 335px;
      background: white;
      margin-bottom: 20px;
      padding-bottom: 20px;
      position: relative;
      &:nth-child(2),
      &:nth-child(5),
      &:nth-child(8) {
        margin-right: 1%;
        margin-left: 1%;
      }
      img {
        width: 100%;
        height: 240px;
        cursor: pointer;
      }
      .icon-bofang {
        color: #fff;
        padding: 1.625rem 1.4375rem 1.5625rem 1.9375rem;
        background: rgba(0, 0, 0, 0.34901960784313724);
        border-radius: 50%;
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }
      .title {
        margin: 10px 15px;
        cursor: pointer;
      }
      .scale-box {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 20px;
        left: 15px;
        right: 0;
        .people {
          background: #e9e9e973;
          padding: 5px 20px;
          font-size: 16px;
          color: #757575;
        }
        .payfor {
          position: absolute;
          right: 15px;
          color: #eb4d37;
        }
      }
    }
    .curriculum-items:hover{
       cursor: pointer;
       box-shadow: 0px 10px 10px #b3b7c8;
    background: #fafafa
    }
  }
  .el-pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    /deep/ li {
      margin: 0 8px !important;
      min-width: 36px !important;
      height: 34px;
      line-height: 36px;
    }
    /deep/ .active {
      color: white;
      background: #314483;
    }
    /deep/ li:hover {
      color: white;
      background: #314483;
    }
    /deep/ button {
      margin: 0 8px !important;
      height: 34px;
      line-height: 36px;
    }
    /deep/ .btn-next {
      padding: 0;
    }
    /deep/ .btn-prev {
      padding: 0;
    }
    /deep/ button:hover {
      color: white;
      background: #314483;
    }
    /deep/ span {
      margin-right: 30px;
      line-height: 36px;
    }
  }
}
</style>